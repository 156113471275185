@import "iconVariables.scss";
@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/iconmoon/icomoon.eot?yqi6h7');
  src:  url('/fonts/iconmoon/icomoon.eot?yqi6h7#iefix') format('embedded-opentype'),
    url('/fonts/iconmoon/icomoon.ttf?yqi6h7') format('truetype'),
    url('/fonts/iconmoon/icomoon.woff?yqi6h7') format('woff'),
    url('/fonts/iconmoon/icomoon.svg?yqi6h7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-analytics {
  &:before {
    content: $icon-analytics;     
  }
}
.icon-big-data {
  &:before {
    content: $icon-big-data;     
  }
}
.icon-code {
  &:before {
    content: $icon-code;     
  }
}
.icon-deep {
  &:before {
    content: $icon-deep;     
  }
}
.icon-planning {
  &:before {
    content: $icon-planning;     
  }
}
.icon-ubuntu {
  &:before {
    content: $icon-ubuntu;     
  }
}
.icon-left-arrow {
  &:before {
    content: $icon-left-arrow; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
