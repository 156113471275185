.show{
    padding: 8px;
    min-height: 126px;

}
.detail{
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.16);
    min-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 14px; 
    position: relative;
    width: 320px;
    box-sizing: border-box;
    right: 1%;
    top: -24px;
    overflow: hidden;
    transition: all 0.2s linear;
    &>div{
        display: flex;
        flex-direction: column;
       
        &>span{
            margin: 4px 0%;
            font-size: 13px;
            display: inline-block;
            min-width: 155px;
            color: #a8a8a8;
            &:nth-child(odd){
                color: #7b7b7b;
            }
        }
        &>img{
            width: 43px;
                        margin-top: 32%;
            position: relative;
            left: 5%;
        }

    }

    
}
.hide{
    transition: all 0.2s linear;
    min-height: 0 !important;
    padding-top: 0 !important;
    height: 0;
}
.history-block{
    width:66%;
    margin: 0 auto;
    min-height: 200px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 31px 0;
    direction: rtl;
    &>div{
        position: relative;
        width: 47%;
        margin-right: 22px;
        &>div{
            margin-right: 20px;
            margin-bottom: 11px;
            position: relative;
        }
        &::before{
            content: "";
            position: absolute;
            height: 70%;
            width: 1px;
            background-color: #96d6f5;
            right: 7px;
            top: 66px;
            z-index: -1;
        }
    }
    .show-title{
        transition: opacity 0.1s linear 0.3s;
        opacity: 1;
        color: #515151;

    }
    .hide-title{
         opacity: 0;
         color: #515151;

    }
    .filled{
        width: 15px;
        height: 15px;
        background-color: #15489f;
    }
    .option{
        display: inline-block;
        cursor: pointer;
        margin-left: 15px;
        position: absolute;
        right: -20px;
        top: 5px;
    }
    .stroke{
        width: 10px;
        height: 10px;
        border: 3px solid #15489f;
        background-color: #ffffff;

    }
    .title{
        margin-bottom: 40px;
        text-align: right;
        color: #121212;
        position: relative;
        .line{
            position: relative;
            display: inline-block;
            width: 50px;
            border-bottom: 2px solid #96d6f5;
            right: 10px;
        }
        // &::before{
        //     content: "";
        //     position: absolute;
        //     width: 50px;
        //     height: 2px;
        //     top: 13px;
        //     background: #96d6f5;
        //     right: 53%;
        // }
    }
 
}



.en .history-block{
    direction: ltr;
    &>div{
        margin-left: 22px;
        margin-right: 0;
        &>div{
            margin-right: 0;
            margin-left: 20px;
        }
        &::before{
            left: 7px;
        }
    }
    .option{
        margin-right: 15px;
        margin-left: 0px;
            right:auto;
            left: -20px;

    }
    .title{
        text-align: left;
        .line{
            left: 10px;
        }

    }

    .show-title{
        font-size: 13px; 

    }
 
}





.en .detail{

    right: 8%;
    left: 1%;
    font-size: 13px; 

}



@media screen and (max-width: 1150px) {
    .history-block{
        & > div{
            width: 95%;
        }
    }
}

@media screen and (max-width: 1063px) {
    .history-block{
        width: 90%;
        & > div{
            width: 95%;
        }
    }
}


@media screen and (max-width: 790px) {
    .detail{
        right: 2%;
        top: -6px;
    }
    .history-block{
        width: 99%;
    }

}

  @media screen and (max-width: 450px) {
    .history-block{
        width: 90%;
        .show-title{
            font-size: 13px;

    
        }
    }
    .detail{
        right: 1%;
        top: -24px;
        min-width: 250px;
        width: 260px;

    }

  }

  

