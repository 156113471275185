.nav-list{
    direction: rtl;
    text-align: left;
    margin-left: 16%;
    padding-top: 20px;
    position: absolute;
    top: 30px;
        li{
        display: inline-block;
        padding: 10px 26px;
        color: #ffffff;
        cursor: pointer;
        font-size: 1.4vw;
        a{
            color: #ffffff;
            text-decoration: none;
        }
    }
        
}

@media screen and (max-width: 790px) {

    .nav-list{
        margin-left: 1%;
            li{
                padding: 10px 13px;
                font-size: 2.4vw;
        }
  }
}

  @media screen and (max-width: 450px) {
    .nav-list{
        margin-left: 1%;
        padding-right: 0;
            li{
                padding: 10px 13px;
                font-size: 3vw;
        }
  }

  .nav-list.en{

        li{
            padding: 10px 10px;
            font-size: 2.6vw;
    }
}
  }
