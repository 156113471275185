$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-analytics: "\e904";
$icon-big-data: "\e905";
$icon-code: "\e906";
$icon-deep: "\e907";
$icon-planning: "\e908";
$icon-ubuntu: "\e909";
$icon-left-arrow: "\e903";
$icon-envelope: "\e900";
$icon-google-plus: "\e901";
$icon-linkedin: "\e902";