nav{
        background-color: #ffffff;
        height: 35px;
ul{
        position: relative;
        width: 97%;
        display: flex;

        .lang{
                margin-top: 2px;
                a{
                        &>div{
                                width: 47px;
                                height: 21px;
                                border-radius: 11px;
                                background-color: #d5eaff;
                                position: relative;
                                .circle{
                                        width: 21px;
                                        height: 21px;
                                        border-radius: 11px;
                                        background-color: #15489f;
                                        display: inline-block;
                                        margin-right: 0px;
                                }
                                .text{
                                        font-size: 14px;
                                        color: #15489f;
                                        position: absolute;
                                        right: 4px;
                                        margin-right: 0px;

                                }
        
                        }
                }

        }

        .lang.en{
                a{
                        &>div{
                                .circle{
                                        right: 0;
                                        position: absolute;
                                }
                                .text{
                                        left: 3px;
                                }
        
                        }
                }

        }

        .linkedin{
                margin-left: 20vw;   
             }
        .google-plus{
                margin-left: 5px;
                }
        .email{
                margin-left: 43%;                .socialMedia{
                        border: none !important;
                        position: absolute;
                        top: 3px;
                }
        }

        li{
                display: inline-block;
                a{
                        span{
                                color: #15489f;
                                font-size: 13px;
                                margin-right: 5px;
                                font-weight: 600;
                                a{
                                color: #15489f;
                                font-size: 13px;
                                margin-right: 5px;
                                font-weight: 600;
                                }
                        }
                        text-decoration: none;
                        .socialMedia{
                                display: inline-block;
                                border-radius: 50%;
                                width: 26px;
                                height: 26px;
                                border: 1px solid #15489f;
                                
                                
                                i{
                                color: #15489f;
                                margin-left: 7px;
                                width: 2.3px;
                                height: 9.5px;
                                font-size: 13px;
                                }

                              
                        }
                }

        }


}

}


@media screen and (max-width: 790px) {

        nav{
                ul{
                        .linkedin{
                                margin-left: 11px;
                                                     }
                        .google-plus{
                                margin-left: 5px;
                                }
                        .email{
                                margin-left: 43%;             
                        }    
                }
        }
      }

      @media screen and (max-width: 450px) {

        nav{
                ul{
                        padding-left: 5px;
                        .email{
                                margin-left: 20%;             
                        }    
                }
        }
      }
