.card{
    background-color: #ffffff;
    width: 100%;
    height: 191px;
    direction: rtl;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 19px;
    .image{
        display: inline-block;
        img{
            height: 191px;
            }
    }
    .fa span{
        display: inline-block;
        color: #7b7b7b;
        text-align: center;
        &.titleCard{
            position: absolute;
            top: 11px;
            right: 25%;
            color: #1c1c1c;
            font-size: 15px;
            text-align: right;
        }
        &.descriptionCard{
            position: absolute;
            top: 56px;
            right: 25%;
            color: #7b7b7b;
            font-size: 14px;
            text-align: justify;
            direction: rtl;
            padding-left: 32px;
        }
    }


    .en span{
        display: inline-block;
        color: #7b7b7b;
        text-align: center;
        &.titleCard{
            position: absolute;
            top: 11px;
            left: 0;
            color: #1c1c1c;
            font-size: 15px;
            text-align: left;
            padding-left: 32px;
            right: 28%;

        }
        &.descriptionCard{
            position: absolute;
            top: 64px;
            color: #7b7b7b;
            font-size: 14px;
            text-align: justify;
            direction: ltr;
            padding-left: 32px;
            left: 0;
            right: 28%;
        }
    }

    .bookLink{
        background: #15489f;
        display: inline-block;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        color: #fff;
        position: absolute;
        left: -12px;
        top: 80px;
        font-size: 12px;
        text-decoration: none;
        cursor: pointer;
        i{
            top: 3px;
            position: relative;
            right: 4px;
        }
        }

        .bookDetail{
            position: relative;
        }
}

@media screen and (max-width: 1230px) {
    .card{
        .fa span{
            &.titleCard{
                right: 28%;
            }
            &.descriptionCard{
                right: 28%;
            }
        }

    
        .en span{
    
            &.titleCard{
                right: 28%;
            }
            &.descriptionCard{
    
                right: 28%;
    
            }
        }
    }


}
@media screen and (max-width: 790px) {
    .card{

        .fa span{

            &.titleCard{
                font-size: 14px;
                top: 4px;
                right: 33%;
            }
            &.descriptionCard{
                top: 72px;
                font-size: 13px;
                right: 33%;
            }
        }
    
    
        .en span{
    
            &.titleCard{
                font-size: 14px;
                top: 4px;
            }
            &.descriptionCard{
                top: 72px;
                font-size: 13px;
                right: 33%;
    
            }
        }
    }
}
@media screen and (max-width: 730px) {
    .card{
        width: 260px;
        height: 580px;
        .image{
            display: block;
            img{
                height: auto;
                width: 260px;                }
        }
        .fa span{
            display: inline-block;
            color: #7b7b7b;
            text-align: center;
            &.titleCard{
                position: relative;
                top: 5px;
                font-size: 15px;
                text-align: center;
                display: block;
                right: 0;
            }
            &.descriptionCard{
                position: relative;
                top: 13px;
                right: 7px;
                display: block;
                font-size: 13px;
            }
        }
    
    
        .en span{
            display: inline-block;
            color: #7b7b7b;
            text-align: center;
            &.titleCard{
                position: relative;
                top: 5px;
                font-size: 14px;
                text-align: center;
                display: block;
                right: 0;
                padding: 0 3px;
            }
            &.descriptionCard{
                position: relative;
                top: 13px;
                right: 7px;
                display: block;
                font-size: 13px;

            }
        }
    
        .bookLink{
            position: absolute;
            left: 46%;
            top: 569px;

            }

    }
}
