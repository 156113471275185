.skills{
    min-height: 425px;
    position: relative;
    margin-bottom: 0;
    background: #f5f5f5;
    .mainBlock{
        display: flex;
        direction: rtl;
        h2{
            color: #191919;
            margin-right: 17%;
            text-align: right;
            font-weight: 600;
            position: relative;
            margin-top: 0;
            min-width: 93px;
            padding-top: 44px;
            &::before{
                width: 5vw;
                height: 3px;
                margin: 0 10px;
                content: '';
                position: absolute;
                bottom: 0;
                background: #6eb6ff;
                border-radius: 3px;
            }
        }
        p{
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.55;
            letter-spacing: normal;
            color: #707070;
            text-align: justify;
            direction: rtl;
            padding: 28px 4% 0 17%;
            margin-bottom: 0;
        }

    }
    .skillList{
        display: flex;
        flex-wrap: wrap;
        width: 64%;
        margin: 0 auto;
        text-align: right;
        direction: rtl;
        color: #121212;
        .title{
            margin-bottom: 40px;
            text-align: right;
            color: #121212;
            position: relative;
            direction: rtl;
            .line{
                position: relative;
                display: inline-block;
                width: 50px;
                border-bottom: 2px solid #96d6f5;
                right: 10px;
            }
        }
        .skillItem{
            width: 50%;
            .item{
                direction: ltr;
                margin-bottom: 19px;
                span{
                    margin-right: 15px;
                }
                i{
                    width: 35px;
                    height: 35px;
                    display: inline-block;
                    border: solid 1px #707070;
                    background-color: #ffffff;
                    color: #15489f;
                    font-size: 22px;
                    position: relative;
                    &::before{
                        position: relative;
                        right: 6px;
                        top: 7px;
                    }
                }
    
            }
        }

    }


}

.skills.en{

    .mainBlock{
        direction: ltr;
        h2{
            margin-left: 17%;
            margin-right: 0;
            &::before{

            }
        }
        p{
            padding: 28px 17% 0 4%;
            direction: ltr;
        }

    }
    .skillList{
        direction: ltr;
        .title{
            text-align: left;
            direction: ltr;
            .line{
                left: 10px;
            }
        }
        .skillItem{
            text-align: left;
            .item{
                direction: rtl;
                left: 6px;
                span{
                    margin-left: 15px;
                }
                i{
                    &::before{
                        left: 6px;
                        right: auto;
                        }
                }
    
            }
        }

    }


}



@media screen and (max-width: 790px) {
    .skills{
        .mainBlock{
            display: block;
            h2{
                &::before{
                    width: 7.5vw;
                }
            }
        }
    }
}




@media screen and (max-width: 590px) {
    .skills{
        .mainBlock{
            display: block;
            h2{
                &::before{
                    width: 10vw;
                }
            }
        }

        .skillList{
            .skillItem{
                width: 100%;
                .item{
                    i{

                        font-size: 22px;

                    }
                    span{
                        font-size: 14px;
                    }
        
                }

                .title{
                    font-size: 16px;
                }
            }
    
        }
    }


    .skills.en .mainBlock h2 {
        text-align: left;
        direction: ltr;
        &::before{
            margin: 0 0px;
        }
    }
  }