.scientific{
    min-height: 425px;
    background: #f5f5f5;
    position: relative;
    margin-bottom: 130px;
        padding-top: 36px;
    .mainBlock{
        h2{
            color: #191919;
            margin-right: 17%;
            text-align: right;
            font-weight: 600;
            position: relative;
            margin-top: 0;
            &::before{
                width: 8vw;
                height: 3px;
                margin: 0 10px;
                content: '';
                position: absolute;
                bottom: 0;
                background: #6eb6ff;
                border-radius: 3px;
            }
        }
        p{
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.55;
            letter-spacing: normal;
            color: #707070;
            text-align: justify;
            direction: rtl;
            padding: 28px 17% 0 17%;
            margin-bottom: 0;
        }

    }
    .history-block{
        position: relative;
        z-index: 999;
        bottom: -65px;
        background: #ffff;
    }

}

.scientific.en{
    .history-block{

        padding-left: 15px;
    }

    .mainBlock{
        h2{
            text-align: left;
            margin-left: 17%;
        }
        p{
            direction: ltr;
        }

    }
}

@media screen and (max-width: 790px) {
    .scientific{
        .mainBlock{
            h2{
                &::before{
                    width: 11vw;       
                         }
            }
    
        }

    
    }
    
}

@media screen and (max-width: 590px) {
    .scientific{
        .mainBlock{
            h2{
                &::before{
                            width: 15vw;     
                        }
            }
    
        }

    
    }
}
@media screen and (max-width: 450px) {

}