.footer{
    width: 67%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    direction: rtl;
    padding: 45px 0 2px 0;
        a{
        text-decoration: none;
    }
        .block-1{
        width: 30%;

        img{
            width: 185px;
        }
        span{
            text-align: justify;
            direction: rtl;
            color: #818181;
            display: block;
            font-size: 15px;
        }

        .socialMedia{
            display: inline-block;
            border-radius: 50%;
            width: 26px;
            height: 26px;
            border: 1px solid #15489f;
            
            
            i{
            color: #15489f;
            font-size: 15px;
            &::before{
                position: relative;
                right: 4px;
            }
            }
    }

    & >div{
        display: inline-block;
        margin: 10px 0 0 10px;
    }

    }
    .block-2{
        width: 26%;
                a{
            display: block;
            color: #696969;
            padding: 2px 3px 7px 0;
                }
    }
    .block-3{
        width: 20%;
        a{
            display: block;
            color: #696969;
            padding: 2px 0 7px 0;
                }
    }
    .block-4{
        width: 24%;
        img{
            width: 187px;
        }

    }

}




.footer.en{
    direction: ltr;
        a{
    }
        .block-1{
        span{
            direction: ltr;
            font-size: 14px;
        }

        .socialMedia{

            i{

            &::before{
                left: 7px;
                right: auto;
            }
            }
    }



    }
    .block-2{
        font-size: 14px;
                a{
                    padding: 6px 0px 0px 7px;
                }
    }
    .block-3{
        font-size: 14px;
        a{
            padding: 6px 0px 0px 0px;
                }
    }
    .block-4{
        text-align: right;

    }

}

@media screen and (max-width: 1157px) {
    .footer {
        width: 90%;

    }
}
@media screen and (max-width: 870px) {
    .footer {
        width: 94%;
        .block-1{
            width: 32%;

        }
        .block-2{
            width: 32%;

        }
        .block-3{
            width: 32%;

        }
    }
}




@media screen and (max-width: 686px) {
    .footer {
        width: 94%;
        
        .block-1{
            width: 85%;
        }
        .block-2{
            width: 50%;

        }
        .block-3{
            width: 50%;

        }
    }
  }

  
@media screen and (max-width: 480px) {
    .footer {
        
        .block-1{
            width: 85%;
        }
        .block-2{
            width: 100%;

        }
        .block-3{
            width: 100%;
            a{
                display: block;
                color: #696969;
                padding: 7px 0 7px 0;
            }
        }
    }


    .footer.en .block-2 a {
        padding: 6px 0px 0px 0px;
    }
  }
  
  