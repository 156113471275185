.firstLook{
    width: 100%;
    margin-bottom: -10px;
    img{
        width: 100%;
    }
    .name{
        position: absolute;
        display: inline-block;
        text-align: right;
        top: 41%;
        right: 61px;
    color: white;
    span{
        font-size: 4.7vw;
        font-weight: 600;
        text-shadow: 0 5px 50px rgba(0, 0, 0, 0.5);
    }
    h1{
        font-size: 4.7vw;   
        font-weight: 600;
        margin-top: 0;
        text-shadow: 0 5px 50px rgba(0, 0, 0, 0.5);
    }
    }
}

.firstLook.en{
        margin-bottom: -60px;
    
    .name{
        text-align: left;
        left: 8%;
    span{
        display: block;
    }

    }
}




@media screen and (max-width: 790px) {

    .firstLook{
        .name{
            top: 25%;
            right: 40px;

        }
    }

    .firstLook.en{
        .name{
            left: 40px;

        }
    }

    
}

  @media screen and (max-width: 450px) {
    .firstLook{
        .name{
            top: 21%;
            right: 10px;

        }
    }
  }
