@import '../../commonStyles/icon.scss';
@font-face {
    font-family: iransans;
    src: url("/fonts/iransans/IRANSans.woff");
}

@font-face {
    font-family: lato;
    src: url("/fonts/lato/Lato.ttf");
}



input,
label,
span,
button,
a,
div,
body,
ul,li {
    font-family: iransans;
    outline: none !important;
}

.en{
    input,
label,
span,
button,
a,
div,
body,
ul,li {
    font-family: lato;
    outline: none !important;
}
}
p{
    font-size: 1.2vw;
}
h2{
    font-size: 2vw;
 
}
body{
    margin: 0;
}
html{
    scroll-behavior: smooth;
}
.copy{
    width: 100%;
    display: inline-block;
    height: 25px;
    text-align: center;
    color: #696969;
    font-size: 11px;
    background-color: #e9e9e9;}


@import '../../commonStyles/mediaQuerys.scss';

.meta-head{
    display: none;
}